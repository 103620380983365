import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { FontStyle } from '../components/GlobalThemes';
import SEO from '../components/SEO';
import usePageContext from '../hooks/usePageContext';
import { Footer } from '../organisms/Footer';
import { Header } from '../organisms/Header';

const OuterWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 70vh;
  margin-top: 60px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
`;

const HeadingBorder = styled.div`
  width: 30vw;
  margin: 0 30px;
  border-top: solid 1px #e5e5e5;
`;

const HeadingH1 = styled.h1`
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -3px;
`;

const NotFoundContent = styled.div`
  margin: -55px 0 25px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  letter-spacing: -1px;
  > p {
    margin: 0;
  }
`;

const NotFoundPage = () => {
  const { title } = usePageContext();

  return (
    <>
      <FontStyle />
      <SEO title={title} />
      <Header />
      <Helmet>
        <title>Page Not Found</title>
      </Helmet>
      <OuterWrapper>
        <InnerWrapper>
          <Heading>
            <HeadingBorder />
            <HeadingH1>Oops!</HeadingH1>
            <HeadingBorder />
          </Heading>
          <NotFoundContent>
            <p>You blew up the Internet.</p>
            <p>The page you are looking for is no longer here.</p>
          </NotFoundContent>
        </InnerWrapper>
      </OuterWrapper>
      <Footer />
    </>
  );
};

export default NotFoundPage;
